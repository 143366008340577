import React from 'react';
import { NextPageContext } from 'next';
import { useTranslation } from 'react-i18next';
import Head from 'next/head';
import * as Sentry from '@sentry/nextjs';
import { LayoutError } from '../blocks/web/layout/error/layout-error';
import { PageError } from '../blocks/web/page-error/page-error';

interface ErrorProps {
    statusCode: number;
    message: string;
}

function Error({ statusCode }: ErrorProps) {
    const { t } = useTranslation();

    const status = [401, 403, 404, 500, 501].includes(statusCode) ? statusCode : 404;

    return (
        <LayoutError>
            <Head>
                <meta property="og:type" content="website" />
                <meta property="og:url" content={`https://monery.io`} />
                <meta property="og:image" content={`https://monery.io/images/social-covers/monery.jpg`} />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={`https://monery.io`} />
                <meta property="twitter:image" content={`https://monery.io/images/social-covers/monery.jpg`} />
                <title>
                    {t(`pages.error.${status}.meta.title`)}
                    {t('meta.title.suffix')}
                </title>
                <meta name="description" content={t(`pages.error.${status}.meta.description`)} />
                <meta property="og:title" content={t(`pages.error.${status}.meta.title`)} />
                <meta property="og:description" content={t(`pages.error.${status}.meta.description`)} />
                <meta property="twitter:title" content={t(`pages.error.${status}.meta.title`)} />
                <meta property="twitter:description" content={t(`pages.error.${status}.meta.description`)} />
            </Head>
            <PageError
                type={status}
                description={t(`pages.error.${status}.description`)}
                buttonText={t(`pages.error.${status}.buttonText`)}
                buttonUrl={'/'}
            />
        </LayoutError>
    );
}

Error.getInitialProps = async (contextData: NextPageContext) => {
    await Sentry.captureUnderscoreErrorException(contextData);

    const { res, err } = contextData;

    // eslint-disable-next-line no-nested-ternary
    const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
    // eslint-disable-next-line no-nested-ternary
    const message = res ? res.statusMessage : err ? err.message : '';
    return { statusCode, message };
};

export default Error;
